import React from 'react'
import styles from '../css/styles.module.css'

function Paragraph2() {

    return (
        <div className={styles.wrapperParagraph2}>
            <div>
                <p>I am currently enrolled in a two-year Java program at Campus Mölndal in Gothenburg. My portfolio showcases some of the projects I have worked on during my Java studies, as well as projects from my previous studies in frontend development.</p>
            </div>
            <div>
                <p>My Exam project aims to explore how machine learning works while applying Python skills and integrating them with Java and React. The outcome is a service that predicts and estimates air quality in specific areas of Stockholm's inner city. Another project is a webshop that I developed collaboratively with other students. During this project, we implemented the Scrum methodology and utilized version control. </p>

            </div>
        </div>
    )
}

export default Paragraph2
